const ENGLISH = {
  groom: "Groom",
  groom_name: "@0xggoma",
  bride: "Bride",
  bride_name: "cutiepie",
  date: "12.34.2022 18:00PM",
  info: "Location",
  message:
    "We are leaving the bank account number for those who want to congratulate us remotely. Thank you!",
  groom_dad: "ggomadad",
  groom_mom: "ggomamom",
  bride_dad: "cutiedad",
  bride_mom: "cutiemom",
  bank: "B(ug)TC",
  kakaopay: "KakaoPay",
  eth: "Ethereum",
  venue: "@0xggoma",
  venue_location: "Follow me :P",
  open_map: "Open in Map",
  groom_parents: "Groom's Parents",
  bride_parents: "Bride's Parents",
  dad: "Father",
  mom: "Mother",
  copied: "Copied to clipbard",
};

export default ENGLISH;
