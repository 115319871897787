const CONSTANTS = {
  GROOM_BANK: "",
  GROOM_ETH: "0x735520F20Ec3FAefe91B2a2318b82D6f9e986eA3",
  GROOM_PARENTS_BANK: "",
  BRIDE_BANK: "",
  BRIDE_KAKAOPAY: "",
  BRIDE_PARENTS_BANK: "",
};

export default CONSTANTS;
