import React from "react";
import pin from "assets/pin.png";
import { getString, useLanguageStore } from "stores/language";

export default function Map() {
  const language = useLanguageStore((state) => state.language);
  return (
    <div className="flex justify-center animate-fadeIn">
      <div className="bg-white p-4 rounded-2xl border border-black flex flex-col justify-between w-full tablet:w-[280px]">
        <div className="flex items-center text-base font-bold">
          <img className="w-8 h-8 mr-1" src={pin} alt="" />
          <div>
            <div>{getString(language, "venue")}</div>
            <div className="font-normal text-xs">
              {getString(language, "date")}
            </div>
          </div>
        </div>

        <div className="flex mt-2.5 text-sm" style={{ wordBreak: "keep-all" }}>
          {getString(language, "venue_location")}
        </div>

        <div className="flex mt-2.5">
          <div
            className="bg-[#463b3c] text-xs cursor-pointer text-white px-4 py-2 rounded-3xl"
            onClick={() => {
              // window.open("http://naver.me/FYuaJ8En", "_blank");
            }}
          >
            {getString(language, "open_map")}
          </div>
        </div>
      </div>
    </div>
  );
}
