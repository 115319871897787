const KOREAN = {
  groom: "신랑",
  groom_name: "꼬꼬마",
  bride: "신부",
  bride_name: "이쁘니",
  date: "12.34.2022 18:00PM",
  info: "장소",
  message:
    "비대면으로 축하를 전하려는 분들을 위해 계좌번호를 남깁니다. 진심으로 축하해주셔서 감사합니다.",
  groom_dad: "꼬마아빠",
  groom_mom: "꼬마엄마",
  bride_dad: "이쁘니아빠",
  bride_mom: "이쁘니엄마",
  bank: "벅트코인",
  kakaopay: "카카오페이",
  eth: "이더리움",
  venue: "@0xggoma",
  venue_location: "팔로우 부탁드려요 :P",
  open_map: "지도에서 열기",
  groom_parents: "신랑측 혼주",
  bride_parents: "신부측 혼주",
  dad: "아버지",
  mom: "어머니",
  copied: "클립보드에 복사하였습니다",
};

export default KOREAN;
