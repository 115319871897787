import create from "zustand";
import { persist } from "zustand/middleware";

import KO from "strings/ko";
import EN from "strings/en";

export const LANG_KO = "ko";
export const LANG_EN = "en";

export const useLanguageStore = create(
  persist(
    (set) => ({
      language: LANG_KO,
      setLanguage: (language) => set({ language }),
    }),
    {
      name: "language",
    }
  )
);

export function getString(language, key) {
  if (language === LANG_KO) {
    return KO[key];
  } else if (language === LANG_EN) {
    return EN[key];
  }
}
